import { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import { useMap } from 'react-leaflet/hooks';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-loading/src/Control.Loading.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import "leaflet-loading";

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});



function ZipCodeMap({ zipData }) {
  const mapRef = useRef(null);
  const m = mapRef.current;
  return (
    <MapContainer
      center={[36.403, -105.59]}
      zoom={10}
      style={{ height: "100%", width: "100%" }}
      whenCreated={(mapInstance) => { mapRef.current = mapInstance; }}
      loadingControl={true}
    >
<TileLayer
  url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${mapboxToken}`}
  attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
  id="mapbox/streets-v11"
  tileSize={512}
  zoomOffset={-1}
/>

{/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
<MarkerClusterGroup>
      {zipData.map((zip, index) => (zip.latitude && zip.longitude)  ? (
        <Marker key={index} position={[zip.latitude, zip.longitude]}>
          <Popup>{zip.zip}</Popup>
        </Marker>
      ): null)}
      </MarkerClusterGroup>
    </MapContainer>
  );
}

export default ZipCodeMap;

