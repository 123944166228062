import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import zdata from './paseo.json';

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;



function CSVUploader({ setZipData }) {
  const [loading, setLoading] = useState(false);
  const [z,setZ] = useState([])
  useEffect(()=> {
    setZipData(zdata)

  },[])
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: (results) => {

        // setZipData(results.data);  // Zip codes will be saved
        reverseGeocodeZips(results.data);
      },
    });
  };
  const handleJSONUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const json = JSON.parse(e.target.result)
        setZipData(json)
      } catch(error) {
        
      }
    }
    reader.onerror = (e) => {
      console.log('error reading file')
    }
    reader.readAsText(file);
  };
  const downloadJSON = () => {
    // Convert array to JSON
    const json = JSON.stringify(z, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.download = 'data.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url); // Clean up
  };
  const reverseGeocodeZips = async (data) => {
    let zips = []
    setLoading(true);
    for (let i = 0; i < data.length; i++) {
      const zipCode = data[i]['zipcode'];
      if (zipCode) {
        let cass = await reverseGeocode(zipCode);
        if(cass && cass.latitude && cass.longitude) {
          zips.push(cass)
          console.log('cass',cass)
        }
        //await delay(150); // 1-second delay to avoid overwhelming the API
      }
    }
    setLoading(false);
    setZipData(zips)
    setZ(zips)
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const reverseGeocode = async (zipCode) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${zipCode}.json?access_token=${mapboxToken}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.features.length > 0) {
        const { center } = data.features[0]; // [longitude, latitude]
        // Here you can store the coordinates for each zip code
        let temp = {zip:zipCode,latitude:center[1],longitude:center[0]}
        return temp;
      } else {
        console.log(`No coordinates found for zip code: ${zipCode}`);
      }
    } catch (error) {
      console.error(`Error fetching data for zip code: ${zipCode}`, error);
    }
  };
  return (
    <div>
    <input type="file" accept=".csv" onChange={handleFileUpload} />
    <input type="file" accept=".json" onChange={handleJSONUpload} />

    <button onClick={downloadJSON}>Download as JSON</button>
    </div>
  );
}

export default CSVUploader;

