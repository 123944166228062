import React, { useState } from 'react';
import CSVUploader from './CSVUploader';
import ZipCodeMap from './ZipCodeMap';
import './App.css';
function App() {
  const [zipData, setZipData] = useState([]);

  return (
    <div className="App">
      <h1>Zip Code Mapper</h1>
      <CSVUploader setZipData={setZipData} />
      <ZipCodeMap zipData={zipData} />
    </div>
  );
}

export default App;

